const EshopHome = () => {
    return (
        <div id="eshop">
            <p>
                E-Shop je v procese rekonštrukcie.
            </p>
        </div>
    )
}

export default EshopHome