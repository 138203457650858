import expect from '../img/advant/expect.png'
import love from '../img/advant/love.png'
import design from '../img/advant/design.png'
import indiv from '../img/advant/indiv.png'
import price from '../img/advant/price.png'
import kitchen from '../img/introProduct/kitchen.jpg'
import door from '../img/introProduct/door.jpg'
import closet from '../img/introProduct/closet.jpg'
import bathroom from '../img/introProduct/bathroom.jpg'
import stairs from '../img/introProduct/stairs.jpg'
import kidsroom from '../img/introProduct/kidsroom.jpg'
import living from '../img/introProduct/living.jpeg'
import misc from '../img/introProduct/misc.jpg'
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux';

const Intro = () => {
    const dispatch = useDispatch();
    return(
        <div id="intro">

            <div class="introFirstDiv">
                <h1 id="headerNameVIN">Výroba interiérového <br></br> nábytku</h1>
                <h2 id="headerNameRJ">Rastislav Jurčík</h2>
            </div>

            <div id="introSecondDiv">
                <p>O nás</p>
                <p>
                Stolárstvo VIN je na trhu od roku 1999 so stolárskou dielňou a sídlom v Poprade. Od nášho vzniku sme uspokojili množstvo zákazníkov, 
                ktorým záleží na kvalitne prevedenej práci s individuálnym prístupom a dodávkou v stanovenom termíne.
                <br></br><br></br>
                Navrhujeme riešenia, podľa predstáv zákazníka, s použitím najnovších materiálov, spojovacích elementov a moderných doplnkov.
                Zákazku k Vám prídeme zamerať, stanovíme cenu a na základe dohody vyrobíme. Dovoz a montáž je pre nás samozrejmosťou.
                Pri našej práci je samozrejmosťou bezplatné poradenstvo pri realizácii interiérov podľa Vašich predstáv.
                <br></br><br></br>
                Tešíme sa na spoluprácu s Vami.
                </p>
            </div>

            <div id="introThirdDiv">
                <p>Naše výhody</p>
                <div className="advantDiv">
                    <p>Návrh a vizualizácia</p>
                    <img src={expect} alt=""></img>
                </div>
                <div className="advantDiv">
                    <p>Cenová ponuka</p>
                    <img src={design} alt=""></img>
                </div>
                <div className="advantDiv">
                    <p>Láska k práci</p>
                    <img src={love} alt=""></img>
                </div>                
                <div className="advantDiv">
                    <p>Splnenie očakávaní</p>
                    <img src={indiv} alt=""></img>
                </div>             
            </div>

            <div id="introFourthDiv">
                <p>Ponúkame</p>
                <Link to="/gallery" type="kitchen">  
                <div className="introProductDiv" onClick={() => {dispatch({ type: 'LISTENTYPE', payload: 'kitchen'})}}>
                    <p>Kuchyne na mieru</p>
                    <img src={kitchen} alt=""></img>
                </div>
                </Link>
                <Link to="/gallery" type="doors"> 
                <div className="introProductDiv" onClick={() => {dispatch({ type: 'LISTENTYPE', payload: 'doors'})}}>
                    <p>Interiérové dvere</p>
                    <img src={door} alt=""></img>
                </div>
                </Link>
                <Link to="/gallery" type="inbuilt" onClick={() => {dispatch({ type: 'LISTENTYPE', payload: 'inbuilt'})}}>
                <div className="introProductDiv">
                    <p>Vstavané skrine</p>
                    <img src={closet} alt=""></img>
                </div>
                </Link>
                <Link to="/gallery" type="bathroom" onClick={() => {dispatch({ type: 'LISTENTYPE', payload: 'bathroom'})}}>
                <div className="introProductDiv">
                    <p>Kúpeľňový nábytok</p>
                    <img src={bathroom} alt=""></img>
                </div>
                </Link>
                <Link to="/gallery" type="stairs" onClick={() => {dispatch({ type: 'LISTENTYPE', payload: 'stairs'})}}>
                <div className="introProductDiv">
                    <p>Schodiská</p>
                    <img src={stairs} alt=""></img>
                </div>
                </Link>
                <Link to="/gallery" type="kids" onClick={() => {dispatch({ type: 'LISTENTYPE', payload: 'kids'})}}>
                <div className="introProductDiv">
                    <p>Detské izby</p>
                    <img src={kidsroom} alt=""></img>
                </div>
                </Link>
                <Link to="/gallery" type="living" onClick={() => {dispatch({ type: 'LISTENTYPE', payload: 'living'})}}>
                <div className="introProductDiv">
                    <p>Obývacie izby</p>
                    <img src={living} alt=""></img>
                </div>
                </Link>
                <Link to="/gallery" type="misc" onClick={() => {dispatch({ type: 'LISTENTYPE', payload: 'misc'})}}>
                <div className="introProductDiv">
                    <p>Ostatné</p>
                    <img src={misc} alt=""></img>
                </div>
                </Link>
            </div>

            <div id="introFifthDiv">
                <h1>E-shop</h1>
                <h2>STOLÁSTVO VIN <br></br>Vám ponúka nákup prostredníctvom nášho nového E-shopu</h2>
                <span>
                    <Link to="/eshopHome">
                        TU
                    </Link>
                </span>
            </div>
           
        </div> 
    )
}

export default Intro