import rootReducer from './reducers';
import { compose, createStore } from 'redux';

const enhancers = [
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
];


const store = createStore(
    rootReducer,
    compose(...enhancers)
  )

export default store;

