import logo from '../img/logo.png'
import fb from '../img/icons/fb.png'
import ggl from '../img/icons/ggl.png'
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom"

const Footer = () => {
    return (
        <div id="footer">
            <img src={logo} alt='' id="footerLogo"></img>            
            <p>Rastislav Jurčík</p>
            <img src={fb} alt='' className="footerIcon"></img>            
            <img src={ggl} alt='' className="footerIcon"></img>

        </div>
    )
}

export default Footer