const References = () => {
    return (
        <div>
            <div className="headerFirstDiv">
                <p className="productsHeader">Referencie</p>
            </div>
            <div id='referencesDiv'>
                <p>Služby stolárstva VIN využili aj tieto firmy:</p>
                <p>Grand Hotel Kempinsky</p>
                <p>Nemocnica Poprad</p>
                <p>Penzióny Chorvátsko</p>
                <p>Vývoz hračiek EU</p>
                <p>...plus veľké množstvo spokojných domácností na Slovensku</p>
            </div>
        </div>
        
    )
}

export default References