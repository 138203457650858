const Contact = () => {
    return (
        <div>
            <div className="headerFirstDiv">
                <p className="productsHeader">Kontakt</p>
            </div>
            <div id='contact'>
                <p>info@stolarstvopoprad.sk</p>
                <p>+421 905 664 361</p>
            </div>
        </div>
        
    )
}

export default Contact