import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';


const Gallery = () => {

    const [productImages, setProductImages] = useState([])
    const [header, setHeader] = useState ('')
    const type = useSelector (state => state.typeReducer)    

    //Import images
    useEffect(() => {        
        async function fetchImages(product) {
            try {
                const response = await fetch(`../php/getimages.php?product=${product}`);
                const data = await response.text();
                const images = data.split("\n");
                setProductImages(images);
            } catch (error) {
                console.error(error);
            }
        }

        switch (type) {
            case 'kitchen':
                fetchImages(type);
                setHeader('Kuchyňa')
                break;
            case 'doors':
                fetchImages(type);
                setHeader('Dvere')
                break;  
            case 'inbuilt':
                fetchImages(type);
                setHeader('Vstavané skrine')
                break;
            case 'bathroom':
                fetchImages(type);
                setHeader('Kúpeľňový nábytok')
                break; 
            case 'stairs':
                fetchImages(type);
                setHeader('Schodiská')
                break;
            case 'kids':
                fetchImages(type);
                setHeader('Detské izby')
                break;  
            case 'living':
                fetchImages(type);
                setHeader('Spálne')
                break;
            case 'misc':
                fetchImages(type);
                setHeader('Ostatné')
                break; 
            default:
                console.log('redux default')
        }
        
    },[])

    const RenderItems = (props) => {
        if (props.images.length > 0) { //Check if images have been loaded
            return (
                props.images.map((image, x) => (
                    <div key={x} className='galleryInnerDiv'>
                        <img src={image} alt=''></img>
                    </div>
                  ))
                
                )
             
        } else {
            return (
                <>
                </>
            )            
        }
    }
    

    return (
        <div>
            <div className="headerFirstDiv">
                <p className="productsHeader">{header}</p>
            </div>
            <div id='galleryOuterDiv'>
                { <RenderItems images={productImages} />  }
            </div>
        </div>
        
        )

    }

export default Gallery