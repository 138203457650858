import logo from '../img/logo.png'
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom"
import React, { useEffect, useState } from 'react'

const Menu = () => {

    const activate = (active) => {
        var b = document.querySelectorAll('.active')
       
        if (b[0].classList) {
            b.forEach(y => {
                y.classList.remove('active');
            })          
        }
        var a = document.getElementById(active)
        a.classList.add('active')
    }


    return (
        <div id='navBar'>
            <img src={logo} alt='' id='navBarLogo'></img>
            <ul>                    
                <li id='landing' className='active' onClick={() => activate('landing')}>
                    <Link to="/">
                        DOMOV
                    </Link>                            
                </li>               
                <li id='products' onClick={() => activate('products')}>
                    <Link to="/products">
                        PONÚKAME
                    </Link> 
                </li>
                <li id="menuReferences" onClick={() => activate('menuReferences')}>
                    <Link to="/references">
                        REFERENCIE
                    </Link> 
                </li>
                <li id="menuEshop" onClick={() => activate('menuEshop')}>
                    <Link to="/eshopHome">
                        E-SHOP
                    </Link> 
                </li>
                <li id="menuContact" onClick={() => activate('menuContact')}>
                    <Link to="/contact">
                        KONTAKT
                    </Link> 
                </li>
            </ul>
        </div>        
    )
}

export default Menu