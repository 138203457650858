import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom"
import Menu from './menu.js' 
import Intro from './intro.js' 
import Footer from './footer.js'
import Products from './products.js'
import Gallery from "./gallery.js"
import References from "./references.js"
import Contact from "./contact.js"
import EshopHome from "./eshopHome.js"

const App = () => {
  return (
    <>      
      <Router> 
      <Menu />
        <Switch>           

          <Route path="/gallery" >
            <Gallery />
          </Route>

          <Route path="/products" >
            <Products />
          </Route> 

          <Route path="/references" >
            <References />
          </Route> 

          <Route path="/contact" >
            <Contact />
          </Route>

          <Route path="/eshopHome" >
            <EshopHome />
          </Route>

          <Route path="/" > {/*Always last in order*/}
            <Intro />
          </Route>

        </Switch>         
      </Router>
      <Footer />
    </>
  );
}

export default App;
