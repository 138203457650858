import list from '../img/icons/list.png'
import kitchen from '../img/introProduct/kitchen.jpg'
import door from '../img/introProduct/door.jpg'
import closet from '../img/introProduct/closet.jpg'
import bathroom from '../img/introProduct/bathroom.jpg'
import stairs from '../img/introProduct/stairs.jpg'
import kidsroom from '../img/introProduct/kidsroom.jpg'
import living from '../img/introProduct/living.jpeg'
import misc from '../img/introProduct/misc.jpg'
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux';
import store from '../redux/store'


const Products = () => {
    const dispatch = useDispatch();
    return (
        <>
            <div className="headerFirstDiv">
                <p className="productsHeader">STOLÁRSTVO VIN ponúka</p>
            </div>

            <div id="workDiv">  

                <Link to="/gallery" type="kitchen">           
                <div className="introProductDiv" onClick={() => {dispatch({ type: 'LISTENTYPE', payload: 'kitchen'})}}>
                    <p>Kuchyne na mieru</p>
                    <img src={kitchen} alt=""></img>
                </div>
                </Link> 

                <Link to="/gallery" type="doors"> 
                <div className="introProductDiv" onClick={() => {dispatch({ type: 'LISTENTYPE', payload: 'doors'})}}>
                    <p>Interiérové dvere</p>
                    <img src={door} alt=""></img>
                </div>
                </Link> 

                <Link to="/gallery" type="inbuilt"> 
                <div className="introProductDiv" onClick={() => {dispatch({ type: 'LISTENTYPE', payload: 'inbuilt'})}}>
                    <p>Vstavané skrine</p>
                    <img src={closet} alt=""></img>
                </div>
                </Link> 
                
                <Link to="/gallery" type="bathroom"> 
                <div className="introProductDiv" onClick={() => {dispatch({ type: 'LISTENTYPE', payload: 'bathroom'})}}>
                    <p>Kúpeľňový nábytok</p>
                    <img src={bathroom} alt=""></img>
                </div>
                </Link> 
                
                <Link to="/gallery" type="stairs" onClick={() => {dispatch({ type: 'LISTENTYPE', payload: 'stairs'})}}> 
                <div className="introProductDiv">
                    <p>Schodiská</p>
                    <img src={stairs} alt=""></img>
                </div>
                </Link> 
                
                <Link to="/gallery" type="kids"> 
                <div className="introProductDiv" onClick={() => {dispatch({ type: 'LISTENTYPE', payload: 'kids'})}}>
                    <p>Detské izby</p>
                    <img src={kidsroom} alt=""></img>
                </div>
                </Link> 
                
                <Link to="/gallery" type="living"> 
                <div className="introProductDiv" onClick={() => {dispatch({ type: 'LISTENTYPE', payload: 'living'})}}>
                    <p>Obývacie izby</p>
                    <img src={living} alt=""></img>
                </div>
                </Link> 
                
                <Link to="/gallery" type="misc"> 
                <div className="introProductDiv" onClick={() => {dispatch({ type: 'LISTENTYPE', payload: 'misc'})}}>
                    <p>Ostatné výrobky</p>
                    <img src={misc} alt=""></img>
                </div>
                </Link> 
            </div>
        </>
    )
}

export default Products